import Vue from 'vue'
import Vuex from 'vuex'
import allowance from './allowance'
import department from './department'
import designation from './designation'
import employee from './employee'
import payroll from './payroll'
import payslip from './payslip'
import overtime from './overtime'
import office_branch from './office_branch'
import company from './company'
import report from './report'
import authService from "../services/authService"
import user from './user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // isAuthenticated: false,
    allowance: [1,2,3],
    signupStatus: '',
    username: '',
    role: '',
    token: ''
  },
  mutations: {
    setAuthentication(state, status) {
        // state.isAuthenticated = status;
        // localStorage.setItem('token', 'token value')
    },
    SIGNUP_EMPLOYEE(state,signupStatus){
      state.signupStatus = signupStatus
    },
    LOGIN_EMPLOYEE(state,userInfo){
      state.username = userInfo.username
      state.role = localStorage.getItem('role')
      // state.isAuthenticated = true
      localStorage.setItem('token', userInfo.accessToken)
    }
  },
  getters: {
    sumAllowance(state){
      return state.allowance.length
    },
    getSignupStatus(state){
      return state.signupStatus
    },
    getRole(state){
      return state.role
    },
    getUsername(state){
      return state.username
    }
  },
  actions:{
    setAuthentication({commit}, status) {
     commit('setAuthentication',status)
    },
    async signupEmployee({commit},employeeInfo) {
      await authService.create(employeeInfo)
      .then(function (res) {
        console.log(res.status);
        commit('SIGNUP_EMPLOYEE',res.status);
      })
    },
    async signinUser({commit},userInfo) {
      await authService.loginUser(userInfo)
      .then(function (res) {
        console.log(res.data.role);
        console.log(res.data.accessToken);
        localStorage.setItem('username', res.data.username)
        localStorage.setItem('role', res.data.role)
        commit('LOGIN_EMPLOYEE',res.data);
      })
    }
  },
  modules: {
    employee: employee,
    payroll: payroll,
    department: department,
    allowance: allowance,
    payslip: payslip,
    designation: designation,
    overtime: overtime,
    office_branch: office_branch,
    company: company,
    report: report,
    user: user
  }
})
